


























































import { Component, Vue } from "vue-property-decorator";
import { api } from "@/api";
import { readResult } from "@/store/main/getters";
import { ICOA } from "@/interfaces";
import { callApi } from "@/store/main/actions";
import NavBar from "@/components/NavBar.vue";
import NavDrawer from "@/components/NavDrawer.vue";
import { formatDate } from "@/date-utils";
import { getResultBreadcrumbs } from "@/breadcrumbs";

@Component({
  components: {
    NavBar,
    NavDrawer,
  },
})
export default class ResultDetails extends Vue {
  coas: ICOA[] = [];

  get result() {
    const result = readResult(this.$store);
    if (!result || result.id !== +this.$route.params.id) {
      return null;
    }
    return result;
  }

  get sampleId() {
    return this.result?.sample_id ? this.result.sample_id : null;
  }

  get sampleIdText() {
    return this.sampleId ? this.sampleId : "";
  }

  get testIdText() {
    return this.result?.test_id ? this.result.test_id : "";
  }

  get showBreadcrumbs() {
    return this.sampleIdText && this.testIdText && this.$route.params.id;
  }

  get breadcrumbs() {
    return this.showBreadcrumbs
      ? getResultBreadcrumbs(
          this.sampleIdText,
          this.testIdText,
          this.$route.params.id,
        )
      : null;
  }

  public async mounted() {
    this.refreshData();
  }

  async refreshData() {
    const response = await callApi(this.$store, () => {
      return api.getCOAs(+this.$route.params.id);
    });
    if (response?.data) {
      this.coas = response.data;
    }
  }

  public formatDate(dateString: string) {
    return formatDate(dateString, null);
  }
}
